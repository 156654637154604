/* @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=VT323&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=VT323&display=swap');

header {
  margin-top: 120px;
  margin-bottom: 24px;
  font-size: 24pt;
  text-align: left;
}

body {
  font-size: 16pt;
  margin-bottom: 120px;
}

p {
  margin-top: 6px;
  margin-bottom:6px;
}

h1 {
  font-family: 'VT323', monospace;
  font-size: 24pt;
  font-weight: 400;
  margin-bottom: 0;
}

thing {
  position: fixed;
  top: 0vh;
  bottom: 0vh;
  left: 55vw;
  width: 35vw;

  padding-top: 10vh;
  padding-bottom: 10vh;

  overflow: scroll; 
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  font-size: 14pt;
}


/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}


a:link {
  color: #202ec6;
}

a:visited {
  color:#202ec6;
}

a:hover {
  background-color: #2a35a6;
  color: #FFFFFF;
  cursor: pointer;
}

.App {
  text-align: center;
}

.not-home {
  background-color: #171717;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'VT323', monospace;
  font-size: 24pt;
  color: #f9fff7;
}

.home {
  color: #171717;
  display: flex;
  font-family: 'VT323', monospace;
  /* font-family:'Times New Roman', Times, serif; */
  margin-left: 10vw;
  /* margin-right: 120px; */
  margin-right: 50vw;
  display: flex;
  flex-direction: column;
}

.itemImage {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 55vw;
  width: 35vw;
  /* border: 3px solid #171717; */
}

.stuff {
  text-decoration: underline;
  color: #398321;
  cursor: pointer;
}

.stuff:hover {
  background-color: #398321;
  color: #FFFFFF;
  cursor: pointer;
}

details {
  /* user-select: none; */
  text-align: left;
  margin-top: 6px;
  /* margin-bottom: 6px; */
}

details > summary::before {
  /* content: '›'; */
  content: '+';
  margin-right: 1ch;
}

details[open] > summary::before {
  content: '—';
  /* transform: rotate(90deg); */
  margin-right: 1ch;
}

summary {
  display: flex;
  cursor: pointer;
  /* margin-top: 0px;
  padding-top: 0px; */
}

summary::-webkit-details-marker {
  display: none;
}

ul {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -16px;
}

/* TODO: fix this */
/* li:before {
  content: "•";
} */

/* #indent {
  margin-top:6px;
  margin-bottom:6px;
  margin-left: 0px;
} */

li {
  margin-left: 6px;
}
